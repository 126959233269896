module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"skills2bank","short_name":"skills2bank","start_url":"/","display":"standalone","display_override":["window-control-overlay","minimal-ui"],"theme_color":"#14AEB8","background_color":"#14AEB8","icon":"src/assets/favicon.png","icons":[{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3c2b055495129c7f51f3d38bcb264556"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
